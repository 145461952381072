import React, { useContext, useState } from 'react';

type LoaderContextType = {
    isLoading: boolean,
    setIsLoading: CallableFunction

}
const inititalvalue = {
    isLoading: false,
    setIsLoading: () => null,

}
const LoaderContext = React.createContext<LoaderContextType>(inititalvalue);


export default LoaderContext;


export const LoaderContextProvider = ({ children }: loaderProviderType) => {
    const [isLoading, setIsLoading] = useState(false);
    return <LoaderContext.Provider value={{ isLoading, setIsLoading }}>{children}</LoaderContext.Provider>
}

export const useLoaderContext = () => {
    return useContext(LoaderContext);
};


type loaderProviderType = {
    children: React.ReactNode
}