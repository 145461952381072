import React from 'react'

const FootageTag: React.FC<FootageTag> = ({ title, isActive, onClick }) => {
  return (
    <li className={`${isActive ? 'active' : ''}`}>
      <a onClick={() => onClick(title)}>{title}</a>
    </li>
  )
}

type FootageTag = {
  title: string
  isActive: boolean
  onClick: CallableFunction
}

export default FootageTag
