export const SideItems = [
  {
    className: '',
    href: '/',
    icon: '/images/icon-home.svg',
    title: 'Home',
    children: [],
  },
  {
    className: 'has-submenu',
    href: '#',
    icon: '/images/icon-search.svg',
    title: 'Search',
    disabled: true,
    children: [],
  },
  {
    className: 'has-submenu',
    href: '#',
    icon: '/images/icon-user.svg',
    title: 'User Profile',
    disabled: true,
    children: [],
  },
  {
    className: 'has-submenu feature-dropdown',
    href: '#',
    icon: '/images/icon-features.svg',
    title: 'Features',
    children: [],
  },
]

export const ContactInfo = [
  {
    className: '',
    href: '/contact',
    title: ' Contact',
    icon: '/images/icon-envelope.svg',
    children: [],
    disabled: false,
  },
  {
    className: 'has-submenu',
    href: '#',
    title: ' FAQs & Help',
    icon: '/images/icon-question-marl.svg',
    children: [],
    disabled: true,
  },
]

export const MoreInfo = [
  {
    className: '',
    href: '/about',
    title: 'About Sphere',

    children: [],
  },
  {
    className: '',
    href: '/termsandcondition',
    title: ' Terms of Use',
    children: [],
  },
  {
    className: '',
    href: '/privacypolicy',
    title: ' Privacy Policy',
    children: [],
  },
]
