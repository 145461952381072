import { createContext, useState, useContext, ReactNode, useEffect } from 'react'
import { categoryContextType, categoryType } from './Categories.type'

type CategoryProps = {
    children: ReactNode
}

const CategoryContext = createContext<categoryContextType>({
    allCategory: [],
    setAllCategory: () => null,
})

export const CategoryContextProvider = ({ children }: CategoryProps) => {
    const [allCategory, setAllCategory] = useState<any>([])

    return <CategoryContext.Provider value={{ allCategory, setAllCategory }}>{children}</CategoryContext.Provider>
}

export const useCategoryContext = () => {
    const { allCategory, setAllCategory } = useContext(CategoryContext)

    return { allCategory, setAllCategory }
}
