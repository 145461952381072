import React from 'react'

const TermsAndPrivacy: React.FC<{ termsOfUse: string; privacyPolicy: string }> = (props) => {
  const { termsOfUse, privacyPolicy } = props

  return (
    <>
      <a className='linkLabel' href='/terms'>
        {termsOfUse}
      </a>
      <a className='linkLabel linkLabelLayout' href='/privacy'>
        {privacyPolicy}
      </a>
    </>
  )
}

export default TermsAndPrivacy
