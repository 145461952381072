import React, { useEffect, useRef, useState } from 'react'
import { matchPath, useNavigate } from 'react-router-dom'
import { useLoaderContext } from '../../../context/LoaderContect'
import { useTopicContext } from '../../../context/Topic'
import { threeDots } from '../../../helper/iconPath'
import { createTopicItem } from '../../../helper/utils'
import Loader from '../../Loader/Loader'
import { ContactInfo, SideItems } from './constant'
import DropDown from './DropDown'
import { NavItem } from './NavItem'
import './SideNavbar.scss'

export const SideNavbar: React.FC = () => {
  const { topics } = useTopicContext()
  const { isLoading } = useLoaderContext()
  const [selected, setNavItem] = useState('')
  const navigate = useNavigate()

  const [dropDownOpen, setDropDown] = useState(false)

  const dropDownRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropDownOpen && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setDropDown(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [dropDownOpen])

  const onClick = (title: string) => {
    setNavItem(selected === title ? '' : title)
  }

  const getClassName = (item: any) => {
    if (item?.isSelected || matchPath({ path: item.href }, location.pathname)) {
      return 'active'
    }

    if (item?.disabled) {
      return 'disable'
    }
    return ''
  }

  const sortedTopics = topics?.sort((a: any, b: any) => {
    return a?.nav_order - b?.nav_order
  })

  return (
    <section className='sidebar-menu  desktop-nav'>
      <a className='brand-name' onClick={() => navigate('/')}>
        CREATOR PORTAL
      </a>
      <div className='three-dots dropend' ref={dropDownRef}>
        <a
          type='button'
          className={`dropdown-toggle ${dropDownOpen ? 'show' : ''} `}
          onClick={() => {
            setDropDown((prev) => !prev)
          }}
        >
          <img src={threeDots} alt='dots' />
        </a>
        <DropDown onClick={() => setDropDown((prev) => !prev)} />
      </div>
      <nav className='sidebar card py-2 mb-4'>
        <ul className='nav flex-column' id='nav_accordion'>
          {SideItems.map((item, index) => {
            return (
              <NavItem
                {...item}
                key={index}
                onClick={onClick}
                isSelected={item.title === selected}
              />
            )
          })}

          {isLoading ? (
            <Loader />
          ) : (
            createTopicItem(sortedTopics).map((item, index) => {
              return (
                <NavItem
                  {...item}
                  key={index}
                  onClick={onClick}
                  isSelected={item.title === selected}
                />
              )
            })
          )}
          <div className='nav-space'></div>
          {ContactInfo.map((item, index) => {
            if (item.title.toLowerCase() === ' contact') {
              return (
                <li key={index} className={`nav-item ${item.className} `}>
                  <a
                    aria-disabled={item.disabled}
                    href={item.href}
                    className={`nav-link ${getClassName(item)}`}
                  >
                    {item.icon && <img src={item.icon} alt='icon' />}{' '}
                    <span className='cursorHighlate'>{item.title}</span>
                  </a>
                </li>
              )
            } else {
              return (
                <NavItem
                  {...item}
                  key={index}
                  onClick={onClick}
                  isSelected={item.title === selected}
                />
              )
            }
          })}
        </ul>
      </nav>
    </section>
  )
}
