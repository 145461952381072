import React from 'react';
import { useTranslation } from 'react-i18next';
import { logoImage } from '../../helper/iconPath';
import { FooterContent } from './constant';
import './Footer.scss';
import { FooterItem } from './FooterItem';

export const Footer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
    <footer>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-10 footer-logo'>
            <div className='footer-msg-logo'>
            <img src={logoImage} alt='logo' />
            <p>
              {t(
                'Creating the future of entertainment.',
              )}
            </p>
            </div>
          </div>
          {FooterContent.map((item, index) => (
            <FooterItem key={index} {...item} />
          ))}
        </div>
      </div>
    </footer>
    <div className="copyright"> © {new Date().getFullYear()} Sphere. All rights reserved. </div>
    </>
  )
}
