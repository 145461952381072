import axios, { AxiosError } from 'axios'

import { END_POINTS } from './domains'

export const GetTopics = async () => {
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))

  try {
    const result: any = await axios.get(END_POINTS.GET_TOPICS, {
      headers: {
        Authorization: userInfo?.token,
        sessionId: userInfo?.sessionId,
      },
    })
    return {
      data: result.data,
      error: false,
    }
  } catch (error: any) {
    if (error?.response?.data?.err?.status == 403) {
      for (let i = 0; i < 3; i++) {
        try {
          const result: any = await axios.get(END_POINTS.GET_TOPICS, {
            headers: {
              Authorization: userInfo?.token,
              sessionId: userInfo?.sessionId,
            },
          })
          return {
            data: result.data,
            error: false,
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    if (error instanceof AxiosError) {
      return {
        error: true,
        code: error?.response?.status,
      }
    }
    return {
      error: true,
    }
  }
}

export const GetArticle = async (id: string) => {
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))

  try {
    const result: any = await axios.get(`${END_POINTS.GET_ARTICLE}/${id}`, {
      headers: {
        Authorization: userInfo?.token,
        sessionId: userInfo?.sessionId,
      },
    })
    return {
      data: result.data,
      error: false,
    }
  } catch (error: any) {
    if (error?.response?.data?.err?.status == 403) {
      for (let i = 0; i < 3; i++) {
        try {
          const result: any = await axios.get(`${END_POINTS.GET_ARTICLE}/${id}`, {
            headers: {
              Authorization: userInfo?.token,
              sessionId: userInfo?.sessionId,
            },
          })
          return {
            result: result.data,
            error: false,
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    if (error instanceof AxiosError) {
      return {
        error: true,
        code: error?.response?.status,
      }
    }
    return {
      error: true,
    }
  }
}

export const GetTopicsById = async (id: string) => {
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))

  try {
    const result: any = await axios.get(`${END_POINTS.GET_TOPICS}/${id}`, {
      headers: {
        Authorization: userInfo?.token,
        sessionId: userInfo?.sessionId,
      },
    })
    return {
      data: result.data,
      error: false,
    }
  } catch (error: any) {
    console.log(error)
    if (error?.response?.status === 403) {
      console.log(error)
    }
    if (error instanceof AxiosError) {
      return {
        error: true,
        code: error?.response?.status,
      }
    }
    return {
      error: true,
    }
  }
}

export const GetAboutPage = async () => {
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))

  try {
    const result: any = await axios.get(`${END_POINTS.GET_ARTICLE}/${'about_page'}`, {
      headers: {
        Authorization: userInfo?.token,
        sessionId: userInfo?.sessionId,
      },
    })
    return {
      data: result.data,
      error: false,
    }
  } catch (error: any) {
    if (error?.response?.status === 403) localStorage.clear()
    if (error instanceof AxiosError) {
      return {
        error: true,
        code: error?.response?.status,
      }
    }
    return {
      error: true,
    }
  }
}

export const GetLandingPage = async () => {
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))

  try {
    const result: any = await axios.get(`${END_POINTS.GET_ARTICLE}/${'landing_page'}`, {
      headers: {
        Authorization: userInfo?.token,
        sessionId: userInfo?.sessionId,
      },
    })
    return {
      data: result.data,
      error: false,
    }
  } catch (error: any) {
    if (error?.response?.status === 403) localStorage.clear()
    if (error instanceof AxiosError) {
      return {
        error: true,
        code: error?.response?.status,
      }
    }
    return {
      error: true,
    }
  }
}
