import { useNavigate } from 'react-router-dom'
import { usePolicyContext } from '../context/Policy'
import { getPolicy } from '../api/policy'
export const usePolicyService = () => {
  const { policy, setPolicy } = usePolicyContext()
  const navigate = useNavigate()
  const getData = async (hasAccepted: boolean) => {
    if (!hasAccepted) {
      const res = await getPolicy()

      if (res.result.article.content) {
        setPolicy(res.result)
        navigate('/policy')
      }
    } else {
      return Promise.resolve(true)
    }
  }
  return getData
}
