import { NavChild } from '../components/SideNavbar/Desktop/NavItem'
import { FootageReponseType } from '../pages/Footage/Footage'

type TopicNavType = {
  children: NavChild[]
  href: string
  title: string
  className: string
  type?: string | null
}

export const createSubtopic = (item: any): NavChild[] => {
  if (item.type === 'footage') {
    const temp: string[] = item.subtopics.map((child: any) => child?.tags)
    const ans = new Set(temp.flat()) // get unique values only
    return Array.from(ans).map((child) => ({
      href: `footage/${item.uid}?tag=${child}`,
      title: child || '',
    }))
  } else {
    return item?.subtopics
      ?.sort((a: any, b: any) => a?.nav_order - b?.nav_order)
      .map((child: any) => ({
        href: `category/${item.uid}/subcategory/${child.uid}`,
        title: child.title,
        uid: child.uid,
      }))
  }
}

export const createTopicItem = (topic: any): TopicNavType[] => {
  const ans = topic?.map((item: any) => {
    const res: TopicNavType = {
      children: [],
      href: '',
      title: '',
      className: '',
      type: '',
    }
    res.children = createSubtopic(item)
    res.href = !item.type ? `category/${item.uid}` : `/footage/${item.uid}`
    res.title = item.title
    res.className = 'has-submenu features-submenu'
    res.type = item.type
    return res
  })

  return ans
}

export function getPaginationItems(
  pageIndex: number,
  pageSize: number,
  items: FootageReponseType[],
) {
  let pageOfItems

  if (!pageIndex && !pageSize) {
    pageOfItems = items
  } else {
    const startIndex = (pageIndex - 1) * pageSize
    pageOfItems = items.slice(startIndex, Math.min(startIndex + pageSize, items.length))
  }

  return pageOfItems
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // You can change this to 'auto' for immediate scrolling
  });
};
