import './features.scss'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { useTopicContext } from '../../../context/Topic'
import { useLoaderContext } from '../../../context/LoaderContect'
import Loader from '../../../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { useMobileScreen } from '../../../hooks/UseMobileScreen'
import { Card } from '../../../components/Card/Card'
import { footage, category } from '../../../helper/constants'
import { useEffect } from 'react'

function Features() {
  const { topics } = useTopicContext()
  const { isLoading } = useLoaderContext()
  const navigate = useNavigate()
  const isMobile = useMobileScreen()

  useEffect(() => {
    // this is jquery script which will only work for the carousel coming from the database
    $('#carouselSlider').ready(function () {
      $('.owl-carousel').owlCarousel({
        nav: true,
        loop: false,
        dots: false,
        margin: 20,
        autoHeight: false,
        stagePadding: 0,
        responsive: {
          0: {
            items: 1,
            stagePadding: 0,
            margin: 20,
          },
          767: {
            items: 2,
            stagePadding: 20,
          },
          1000: {
            items: 3,
          },
        },
      })
    })
  }, [topics])

  return (
    <section id='landingSlider' className='landing-slider-section'>
      <div className='row'>
        <div className='large-12 columns'>
          <div className='sphere-feature'>Sphere Features</div>
          {isLoading ? (
            <Loader />
          ) : (
            <div id='carouselSlider'>
              <div className='owl-carousel'>
                {topics.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      img={item?.heroBanner}
                      title={item?.title}
                      discription={item?.description}
                      onClick={() =>
                        navigate(`/${item.type === footage ? footage : category}/${item.uid}`)
                      }
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
export default Features
