import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetArticle } from '../../../api/Topic.services'
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import Loader from '../../../components/Loader/Loader'
import { useSubcategoryContext } from '../../../context/Context'
import { useTopicContext } from '../../../context/Topic'
import './subcategory.scss'
import '../category.scss'
import SubcategoryNavigator from './SubcategoryNavigator'
import { logoImage } from '../../../helper/iconPath'
import { TopicType, SubTopicType } from '../../../context/Topic.type'
import { usePolicyService } from '../../../hooks/usePolicyService'
import { useCategoryContext } from '../../../context/Categories'

export const SubCategory: React.FC = () => {
  const [currentCategory, setCurrentCategory] = useState<TopicType | null>(null)
  const { subId } = useParams()
  const { id } = useParams()
  const { subCategory } = useSubcategoryContext()
  const [currentSubCategory, setcurrentSubCategory] = useState<SubTopicType | null>(null)
  const { topics } = useTopicContext()
  const navigate = useNavigate()
  const getData = usePolicyService()
  const [loader, setloader] = useState(true)
  const { allCategory, setAllCategory } = useCategoryContext()

  useEffect(() => {
    const item = topics.find((item: any) => item.uid === id)
    if (item) {
      setCurrentCategory(item)
    }

    const articleDataInContext =
      allCategory && allCategory?.length && allCategory?.length > 0
        ? allCategory.find((x: any) => x?.uid == subId)
        : false

    const subItem = item?.subtopics.find((nitem: any) => nitem.uid === subId)
    const fetchData = async () => {
      setloader(true)
      const result = await GetArticle(subItem?.uid || '')
      if (result.data) {
        setloader(false)
        getData(result.data.policyAccepted)
      }

      if (!result.error) {
        try {
          setAllCategory((prev: any) => {
            const data = [
              ...prev,
              { uid: result?.data?.article?.uid, articleData: result?.data?.article },
            ]
            return data
          })
        } catch (err) {
          console.log({ err })
        }
        setcurrentSubCategory({ ...item, ...result?.data?.article, name: subItem?.title || '' })
        // this is jquery script which will only work for the carousel coming from the database
        $('#carouselSlider').ready(function () {
          $('.owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            responsiveClass: true,
            dots: true,
            responsive: {
              0: {
                items: 1,
                nav: true,
              },
              600: {
                items: 1,
                nav: false,
              },
              1024: {
                items: 1,
                nav: true,
                loop: false,
                margin: 20,
              },
            },
          })
        })
      } else {
        setloader(false)
        if (result.error && result?.code === 403) {
          navigate('/login')
        }
      }
    }

    if (!articleDataInContext) {
      fetchData()
    } else {
      setcurrentSubCategory({
        ...item,
        ...articleDataInContext?.articleData,
        name: articleDataInContext?.articleData?.title || '',
      })
    }
  }, [subId, topics])

  function createMarkup(htmlString: string) {
    return { __html: htmlString }
  }

  return (
    <div className='category__wrapper'>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className='msg-sphere-logo'>
            <img src={logoImage} alt='logo' />
          </div>

          <section className='category-page sub-category-section'>
            <Breadcrumb
              route={[
                { link: `category/${currentCategory?.uid}`, text: currentCategory?.title ?? '' },
                { link: 'subcategory', text: currentSubCategory?.name ?? '' },
              ]}
            />
            {currentSubCategory?.content && (
              <>
                <div dangerouslySetInnerHTML={createMarkup(currentSubCategory?.content)} />
                {subCategory ? <SubcategoryNavigator data={subCategory} /> : <Loader />}
              </>
            )}
          </section>
        </>
      )}
    </div>
  )
}
