export const imageUrl = 'images'
export const logoUrl = 'logoImages'
export const logoImage = `/${logoUrl}/msg-sphere_logo.svg`
export const loginImage = `${logoUrl}/LoginImage.png`
export const aboutImage = `${imageUrl}/about-img.png`
export const threeDots = `/${imageUrl}/icon-three-dots.svg`
export const banner = `${imageUrl}/audio-banner-img.png`
export const logoutIcon = `/${imageUrl}/icon-logout.svg`
export const next = `/${imageUrl}/Next.svg`
export const back = `/${imageUrl}/Back.svg`
