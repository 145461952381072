import { RouteObject, useRoutes } from 'react-router-dom'
import PrivacyPolicy from '../pages/Policies/PrivacyPolicy'
import { About } from '../pages/About/About'
import Category from '../pages/Category'
import { SubCategory } from '../pages/Category/SubCategory/SubCategory'
import Home from '../pages/Home'
import LogIn from '../pages/login'
import MainWrapper from '../pages/MainWrapper/'
import { TermsAndConditions } from '../pages/Policies/Terms/TermsAndConditions'
import Footage from '../pages/Footage/Footage'
import FootageVideo from '../pages/Footage/FootageVideo'
import Policy from '../components/Policy/Policy'
import { Contact } from '../pages/Contact/Contact'

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LogIn />,
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/policy',
    element: <Policy />,
  },

  {
    path: '/terms',
    element: <TermsAndConditions />,
  },

  {
    path: '/',
    element: <MainWrapper />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: 'category/:id',
        element: <Category />,
      },
      {
        path: 'category/:id/subcategory/:subId',
        element: <SubCategory />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: 'privacypolicy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'termsandcondition',
        element: <TermsAndConditions />,
      },
      {
        path: 'footage/:id',
        element: <Footage />,
      },
      {
        path: 'testfootage/footagevideo/:data',
        element: <FootageVideo />,
      },
    ],
  },
]

function Router() {
  const element = useRoutes(routes)
  return element
}

export default Router
