import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InputField from '../../components/inputField/InputField'
import TermsAndPrivacy from '../../components/termsandprivacy/TermsAndPrivacy'
import './Login.scss'
import LoginCarousel from '../../components/Carousel/LoginCarosel'
import { useNavigate } from 'react-router-dom'
import { serverMessage, validationMessage } from '../../helper/constants'
import { UserLogIn } from '../../api/Auth.services'
import { AuthActionNames, useAuthContext } from '../../context/Auth'
import Loader from '../../components/Loader/Loader'
import { usePolicyService } from '../../hooks/usePolicyService'

const LogIn: React.FC = () => {
  const [loginData, setLoginData] = useState<UserSchema>({
    password: '',
    email: '',
    errorMessage: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const { dispatch } = useAuthContext()
  const [current, setCurrent] = useState<number>(0)
  const ref = useRef<HTMLInputElement>(null)
  const [loginUser, setloginUser] = useState()
  const { t } = useTranslation()
  const { email, password, errorMessage } = loginData
  const navigate = useNavigate()
  const getData = usePolicyService()
  /* This method helpful to set the state user entered value */
  const handleChange = (value: number) => {
    setCurrent(value)
  }

  /* This method helpful to set the state with from values */
  const handleChangeInput = (event: any) => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value, errorMessage: '' })
  }

  /* This method helpful to apply styles to carousel */
  useEffect(() => {
    ref.current!.style.transition = 'all 0.2s ease-in-out'
    ref.current!.style.transform = `translateX(-${current}00%)`
  }, [current])

  /* This method helpful to add color to user clicked bar */
  const handleLabelColor = (value: number) => {
    if (current === value) return 'gallery-dots active'
    else return 'gallery-dots'
  }

  /* This method helpful to save the user inputvalues to database */
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (email.length > 0 && password.length > 0) {
      setIsLoading(true)
      const result: any = await UserLogIn(email, password)
      if (result.error) {
        setIsLoading(false)
        setLoginData((prev) => ({ ...prev, errorMessage: serverMessage }))
      } else {
        if (result.data.success) {
          setloginUser(result.data)
          setIsLoading(false)
          localStorage.setItem(
            'userInfo',
            JSON.stringify({
              ...result.data.userInfo,
              token: result.data.token,
              sessionId: result.data.sessionId,
            }),
          )
          dispatch({
            type: AuthActionNames.login,
            payload: {
              ...result.data.userInfo,
              policyAccepted: result.data.policyAccepted,
              email: result.data.email,
            },
          })

          const isPolicyAccepted = await getData(result.data.policyAccepted)
          if (isPolicyAccepted) {
            navigate('/')
          }
        } else {
          setLoginData((prev) => ({ ...prev, errorMessage: result.data.message }))
          navigate('/login')
        }
      }
    } else {
      setIsLoading(false)
      setLoginData((prev) => ({ ...prev, errorMessage: validationMessage }))
    }
  }

  return (
    <div className='logInContainer'>
      <div className='first-txt'>{t('Creator Portal')}</div>
      <div className='imageLabel'>
        <div className='box-container' ref={ref}>
          <img src='images/login-bg.png' className='imageLayout' />
          <img src='images/login-bg.png' className='imageLayout' />
          <img src='images/login-bg.png' className='imageLayout' />
        </div>
        <div className='second-txt'>{t('Become part of the new platform for today’s artists')}</div>
        <div className='gallery-dots-container'>
          <LoginCarousel handleLabelColor={handleLabelColor} handleChange={handleChange} />
        </div>
      </div>
      <div className='logInFieldsContainer'>
        <div className='imageLabelPosition'>
          <img src='logoImages/MSGSphere.png' />
        </div>
        <div className='loginAlignmentContainer'>
          <div className='loginContainerLabel'>
            <div className='loginTitle'>{t('log In')}</div>
            <div>
              <InputField
                type='email'
                name='email'
                placeholder={t('Email address')}
                value={email}
                handleChangeInput={handleChangeInput}
              />
            </div>
            <div>
              <InputField
                type='password'
                name='password'
                placeholder={t('Password')}
                value={password}
                handleChangeInput={handleChangeInput}
              />
            </div>
            {errorMessage.length > 0 && <div className='errorDisplay'>{errorMessage}</div>}

            <button
              className='buttonLabel'
              onClick={handleSubmit}
              disabled={errorMessage.length > 0}
              aria-disabled={errorMessage.length > 0 ? 'true' : 'false'}
            >
              {t('Next')}
              {isLoading && <Loader />}
            </button>
            <p className='forgot-pwd'>
              <a aria-disabled='true'>
                {t('Forgot your password')}
                <b>?</b>
              </a>
              {/* <a className='linkLabel'>{t('Forgot your password')}</a> */}
            </p>

            <div className='linkLabelContainer'>
              <div className='linkLabelPosition'>
                <TermsAndPrivacy
                  termsOfUse={t('Terms of Use')}
                  privacyPolicy={t('Privacy Policy')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogIn
interface UserSchema {
  email: string
  password: string
  errorMessage: string
}
