import React from 'react'
import { useTranslation } from 'react-i18next'

export const Card: React.FC<Card> = ({ img, onClick, title, discription, className }) => {
  const { t } = useTranslation()
  const parser = new DOMParser()

  const formattedTitle = parser?.parseFromString(title, 'text/html')?.body?.textContent || ''
  const formattedDescription = parser?.parseFromString(discription, 'text/html')?.body?.textContent || ''
  return (
    <div style={{ backgroundImage: `url(${img})`,cursor:'pointer' }} className={`item ${className}`} onClick={() => onClick()}>
      <img src={img} alt='img' />
      <h4>{t(formattedTitle)}</h4>
      <p>{t(formattedDescription)}</p>
    </div>
  )
}

type Card = {
  className?: string
  img: string
  onClick: CallableFunction
  title: string
  discription: string
}
