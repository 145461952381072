import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import './privacypolicy.scss'

export const PrivacyPolicy = () => {
  return (
    <section className='terms-of-use'>
      <Breadcrumb route={[{ link: 'privacypolicy', text: 'Privacy Policy' }]} />
      <h4 className='header'>MADISON SQUARE GARDEN PRIVACY POLICY</h4>
      <div className='content'>
        <div className='body-copy'>
          <h3>Introduction</h3>

          <p>
            This Madison Square Garden Privacy Policy (&quot;Policy&quot;) describes the information
            that the Madison Square Garden Family (“collectively, the “MSG Family” defined below)
            collects, how we use it, and when and with whom we may disclose it. Except as described
            in this Policy, the Policy applies to any and all websites, mobile applications software
            and any other electronic and/or digital products and/or other services that are made
            available by the MSG Family, and our offline services (collectively, the
            &quot;Services&quot;). This Policy applies regardless of the device used to access the
            Services.{' '}
          </p>
          <p>
            All services that are governed by this Policy will include a link to, and/or copy of,
            this Policy on the home page of the applicable website or similar location (e.g., a
            “settings” page) for any applicable mobile applications. Some services offered by or
            affiliated with the MSG Family may be governed by a separate privacy policy, and if this
            Policy is not posted on the home page for a particular service or entity that is part of
            the MSG Family, this policy does not apply. In those instances, the service-specific
            privacy policy shall apply to that specific service. Please be advised that this Policy
            does not cover everything related to our Services. For example, if you obtain tickets to
            events hosted at an MSG Family venue, you may be providing personal data to third
            parties such as ticketing agencies or event producers. Those third parties’ own privacy
            policies will apply to the handling of the personal data that they collect.
          </p>
          <p>
            For purposes of this Policy, the terms &quot;MSG Family,&quot; “we,” “us,” and “our”
            include, without limitation, three groups of entities: (i) Madison Square Garden Sports
            Corp. and each of its respective subsidiaries, divisions, affiliated entities and
            business units as may change from time to time (including, without limitation, MSG
            Sports, LLC, New York Knicks, New York Rangers, Westchester Knicks, Hartford Wolf Pack;
            (ii) Madison Square Garden Entertainment Corp. and each of its respective subsidiaries,
            divisions, affiliated entities and business units, as may change from time to time
            (including, without limitation, MSG Entertainment Holdings, LLC, Madison Square Garden,
            the Arena and the Hulu Theater at Madison Square Garden, Radio City Music Hall, The
            Beacon Theatre, The Chicago Theatre, The Rockettes, and MSG Interactive, LLC)
            (collectively, the “MSG Entertainment Family”) and (iii) Sphere Entertainment Co. and
            each of its respective subsidiaries, divisions, affiliated entities and business units,
            as may change from time to time (including, without limitation, MSG Sphere Studios, LLC,
            MSG Las Vegas, LLC and Sphere Entertainment Group, LLC and Sphere (collectively, the MSG
            Sphere Family”).
          </p>
        </div>
        <div className='body-copy'>
          <h3>Introduction</h3>
          <p>Under applicable law: </p>
          <ul>
            <li>
              MSG Sports, LLC is considered the “data controller” of the personal data that the MSG
              Sports Family handles under this Policy.
            </li>
            <li>
              MSG Entertainment Holdings, LLC is considered the “data controller” of the personal
              data the MSG Entertainment Family handles under this Policy.
            </li>
            <li>
              Sphere Entertainment Group, LLC is considered the “data controller” of the personal
              data the MSG Sphere Family handles under this Policy.
            </li>
          </ul>
          <p>
            In other words, each of the entities above is responsible for deciding how the entities
            that are part of MSG Sports Family, MSG Entertainment Family, and MSG Sphere Family
            respectively collect, use and disclose the data they handle. Each entity’s contact
            information appears at the end of this Policy in the Data Controllers Contact
            Information section.
          </p>
          <p>
            Before you submit any information on, through, or in connection with the Services,
            please carefully review this Policy. By submitting any personal data directly to us or
            using any part of the Services, you understand the manner in which we collect, use, and
            disclose your information as described in this Policy. We will continue to evaluate this
            Policy as we update and expand the Services and our offerings, and we may make changes
            to it. Any changes will be posted here, and you should check this page periodically for
            updates. Your continued use of the Services will signify your continued understanding of
            the terms of the updated Policy.
          </p>
          <p>
            This Policy is split into sections. For your convenience, links to each of those
            sections follows:
          </p>
          <p>
            <a href='#infoCollect'>INFORMATION WE COLLECT</a>
          </p>
          <p>
            <a href='#howUseInfo'>HOW WE USE YOUR INFORMATION</a>
          </p>
          <p>
            <a href='#whenDiscloseInfo'>WHEN WE DISCLOSE YOUR INFORMATION</a>
          </p>
          <p>
            <a href='#legalBases'>OUR LEGAL BASES FOR PROCESSING YOUR PERONSAL DATA </a>
          </p>
          <p>
            {' '}
            <a href='#internationalTransfer'>INTERNATIONAL TRANSFER</a>
          </p>
          <p>
            <a href='#personalizedContent'>PERSONALIZED CONTENT AND ADVERTISING AND YOUR CHOICES</a>
          </p>
          <p>
            <a href='#dataRights'>YOUR DATA RIGHTS AND CHOICES</a>
          </p>
          <p>
            <a href='#noticeCalifornia'>NOTICE TO CALIFORNIA RESIDENTS</a>
          </p>
          <p>
            <a href='#privacyInfo'>PRIVACY INFORMATION FOR NEVADA RESIDENTS</a>
          </p>
          <p>
            <a href='#links'>LINKS</a>
          </p>
          <p>
            {' '}
            <a href='#security'>SECURITY AND RETENTION OF YOUR INFORMATION</a>
          </p>
          <p>
            <a href='#infoChildren'>INFORMATION FROM CHILDREN</a>
          </p>
          <p></p>
          <a href='#contactUs'>CONTACTING US ABOUT THIS POLICY</a>
          <p>
            <a href='#changePolicy'>CHANGES TO THIS POLICY</a>
          </p>
          <p>
            <a href='#dataController'>DATA CONTROLLER CONTACT INFORMATION</a>
          </p>
        </div>
        <div className='body-copy'>
          <h3>Data Controllers</h3>
          <h3 id='infoCollect'>1. INFORMATION WE COLLECT</h3>
          <p>
            We collect information in multiple ways, including when you provide information directly
            to us, and when we passively collect information from you, such as information collected
            from your browser or device.
          </p>
          <h5 className='heading'>A. Information You Provide Directly to Us </h5>
          <p>
            We may collect information from you during your use of or access to the Services, such
            as:
          </p>
          <ul>
            <li>When you register for an account;</li>
            <li>When you make a purchase;</li>
            <li>When you enter a sweepstakes or contest or register for a promotion;</li>
            <li>
              When you request certain features (e.g., newsletters, updates, and other products);
            </li>
            <li>When you download or use one of our mobile applications;</li>
            <li>When you communicate with us;</li>
            <li>
              When you post User Content, including comments, to or on any of our Services; or
            </li>
            <li>When you attend an event at one of our venues.</li>
          </ul>
          <p>
            The information you provide directly to us may concern you or others and includes, but
            is not limited to: (i) name; (ii) email address; (iii) age; (iv) mailing address; (v)
            hobbies; (vi) sports and entertainment interests; (vii) telephone number; (viii) fax
            number; (ix) viewpoints on certain events; (x) birthdate; (xi) in certain circumstances,
            payment and identity verification information, such as (1) credit card number, (2)
            social security number (or comparable national identifier) and/or (3) driver’s license
            number when needed for payment in connection with a purchase; and/or (xii) biometric
            information. Payment card information is processed by our third party payment
            processors. Certain Services may not be accessible or available absent account
            registration and/or your provision of certain requested information.
          </p>
          <p>
            Please be careful and responsible whenever you are online. If you choose to post User
            Content (as described in our &nbsp;
            <a href={'https://www.msgentertainment.com/terms'}>Terms of Use</a> &nbsp; and/or{' '}
            <a href='https://www.msg.com/sms-terms-and-conditions'> SMS Terms</a> through the
            Services, such as via social networking services, on message boards, chat areas,
            comments and/or other postings, that information: (i) is publicly available; (ii) may be
            collected and used by third parties without our knowledge; and (iii) may result in your
            receipt of unsolicited messages from other individuals or third parties.
          </p>
          <h5 className='heading'>
            B. Information That is Passively and/or Automatically Collected
          </h5>
          <p className='heading italic points'>(i) Device/Usage Information</p>
          <p>
            We may automatically collect certain information about the computer or devices
            (including mobile devices) you use to access the Services. As described further below,
            we may collect and analyze information such as (a) IP addresses, geo-location
            information, unique device identifiers and other information about your computer or
            mobile phone or other mobile device(s), browser types, browser language, operating
            system, the state or country from which you accessed the Services; and (b) information
            related to the ways in which you interact with the Services, such as: referring and exit
            pages and URLs, platform type, the number of clicks, domain names, landing pages, pages
            and content viewed and the order of those pages, the amount of time spent on particular
            pages, the date and time you used the Services, the frequency of your use of the
            Services, error logs, and other similar information. As described further below, we may
            use third-party analytics providers and technologies, including cookies and similar
            tools, to assist in collecting this information.
          </p>
          <p className='heading italic points'>(ii) Location Information</p>
          <p>
            We also may automatically collect location information, including general location
            information (e.g., IP address, zip code) from your computer or mobile device. If you
            access the Services through a mobile device, we may ask you to allow us to access your
            precise geo-location information so that we can deliver customized content and
            advertising. We and our service providers may also collect other information based on
            your location and your device’s proximity to “beacons” and other similar indoor
            proximity systems, including, for example, the strength of the signal between the
            “beacon” and your device and the duration that your device is near the “beacon”. The
            type of location data we collect depends on a number of factors, including the type of
            device you are using (e.g., laptop, smartphone, tablet) and the type of connection
            enabled on your device (e.g., Wi-Fi, Bluetooth, broadband, cable, or cellular). The
            Services rely on this location information, including geo-fencing technology, in order
            to provide certain location-based services, content, promotional offers and other
            information that may be of interest to you or that may be available to you based on your
            subscriptions to Services we provide. We may also use location information to improve
            the functionality of the Services and our other products and services. If you do not
            wish to have this location information collected and used by the MSG Family and its
            service providers, you may disable the location and Bluetooth features on your device.
            Please note that, if you disable such features, you will not be able to access or
            receive some or all of the services, content, features and/or products made available
            via the Services. To deliver customized content and advertising, we may disclose your
            location information, including geo-location information, to our agents, vendors, or
            advertisers.
          </p>
          <p>
            Depending on the device you are using, we may use third party services to provide
            mapping services. For example, the MSG Family may use Google maps on certain devices—you
            can learn more about the information that Google collects through its mapping services
            at &nbsp;
            <a href='http://www.google.com/privacy.html'>http://www.google.com/privacy.html</a>
          </p>
          <p className='heading italic points'>(iii) Cookies and Other Electronic Technologies</p>
          <p>
            We may also collect data about your use of the Services through the use of web server
            logs, cookies and/or tracking pixels. A web server log is a file where website activity
            is stored. A cookie is a small text file that is placed on your computer when you visit
            a website, that enables us to: (i) recognize your computer; (ii) store your preferences
            and settings; (iii) understand the web pages of MSG Family’s Services you have visited;
            (iv) enhance your user experience by delivering content specific to your interests; (v)
            perform searches and analytics; and (vi) assist with security administrative functions.
            Some cookies are placed in your browser cache, while those associated with Flash
            technologies are stored with your Adobe Flash Player files. Tracking pixels (sometimes
            referred to as “web beacons” or “clear GIFs”) are electronic tags with a unique
            identifier embedded in websites, online ads and/or email, and are designed to provide
            usage information like ad impressions or clicks, measure popularity of the Services and
            associated advertising, and access user cookies. We may also use these automated
            technologies to collect and analyze other information related to the devices you use to
            access the Services, such as IP addresses, browser types, browser language, operating
            system used, the domain name of your Internet service provider, unique device
            identifiers, and other information about your devices used to access the Services. These
            automated technologies may also collect information on the websites you have visited
            before and after you visit the Services, and the advertisements you have accessed, seen,
            forwarded, and/or clicked on when using the Services or other websites. We or
            third-party technologies we use may place or recognize a unique cookie on your browser
            to enable you to receive customized content, offers, services or advertisements on our
            websites or other sites. The cookies may be associated with demographic or other data
            linked to or derived from data you voluntarily have submitted to us (e.g., your email
            address), which we may share with vendors to deliver advertisements for our services on
            our behalf. As we adopt additional technologies, we may also gather additional
            information through other methods
          </p>
          <p>
            Please note that you can change your settings to notify you when a cookie is being set
            or updated, or to block cookies altogether. Please consult the &quot;Help&quot; section
            of your browser for more information (e.g.,{' '}
            <a href='http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies'>
              Internet Explorer
            </a>
            , <a href='https://support.google.com/chrome/answer/95647?hl=en'>Google Chrome</a> ,
            <a href='http://kb.mozillazine.org/Cookies#Firefox'>Mozilla Firefox</a> , or{' '}
            <a href='http://support.apple.com/kb/PH5042'>Apple Safari</a> ). Please note that by
            blocking any or all cookies, you may not have access to certain features or offerings of
            the Services.
          </p>
          <p>
            We use third parties to assist us in serving advertisements, tracking aggregated and/or
            anonymized site usage statistics, and providing content-sharing services to support the
            Services. These third parties may also use cookies and similar technologies to collect
            similar information about your use of the Services. We do not control these third-party
            technologies, and their use is governed by those parties&apos; privacy policies.
          </p>

          <h5 className='heading'>
            C. Information Collected By and From Social Media and Other Content Platforms
          </h5>
          <p>
            If you access the Services through a third-party connection or log-in, such as through a
            social network, like Facebook or Twitter, you may allow us to have access to certain
            information in your profile. This may include your name, e-mail address, photo, gender,
            birthday, location, an ID associated with the applicable third party platform or social
            networking account, user files like photos and videos, your list of friends, people you
            follow and/or who follow you, or your posts or “likes.” For example, if you access the
            Services through Facebook, we may collect the following information about you from
            Facebook: (i) Facebook ID/User Name; (ii) first and last name; (iii) anything that you
            have made public in your Facebook profile; (iv) gender; (v) locale; (vi) age range;
            (vii) profile rank; (viii) your location; (ix) your Facebook “likes”; and (x) your
            comments. If you permit, we also may collect your birthday and email. If you do not wish
            to have this information disclosed to Facebook, do not use Facebook Connect to access
            the Services. We may also collect other information through other social networks that
            you make available to us. For a description on how social networking sites handle and
            disclose your information, please refer to their privacy policies and terms of use,
            which may permit you to modify your privacy settings. We may combine information that we
            collect from or about you with information about you that we obtain from such social
            media and other content platforms and information derived from any other subscription,
            product, or service we provide.
          </p>
          <h5 className='heading'>
            D. Information from Affiliates and Non-Affiliated Third Parties and Partners
          </h5>
          <p>
            We may also obtain information about you from our affiliates including MSG Networks
            Inc., MSGN Holdings, L.P. and each of their respective parent companies, subsidiaries,
            divisions, affiliated entities and business units as may change from time to time
            (including, without limitation, MSGN Songs, LLC, MSGN Publishing, LLC, MSGN Interactive,
            LLC, SportsChannel Associates and The 31st Street Company, LLC). We also may obtain
            information about you from third parties such as marketers, partners (like the NBA, NHL,
            and Ticketmaster), researchers, sponsors, and others. We may combine information that we
            collect from you with information about you that we obtain from our affiliates and/or
            such third parties and information derived from any other subscription, product, or
            service we provide.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='howUseInfo'>
            2. HOW WE USE YOUR INFORMATION
          </h3>
          <p>The MSG Family may use your information for the following purposes:</p>
          <ul>
            <li>For the purposes for which you provided it;</li>
            <li>To provide the features, services, and products available through the Services;</li>
            <li>
              To send you information about your relationship or transactions with us; To send you
              alerts or other communications;
            </li>
            <li>With your consent, to send you SMS messages;</li>
            <li>
              To notify you about new features and offerings of the Services, including, but not
              limited to, discounts, events, shows, news about our Services, products, other
              services, and/or special offers;
            </li>
            <li>To administer sweepstakes and contests;</li>
            <li>To process and respond to your inquiries;</li>
            <li>To contact you with information that we believe may be of interest to you;</li>
            <li>To attribute content that you make available through the Services;</li>
            <li>For internal research and reporting and data analytics;</li>
            <li>
              To improve the content and features of the Services and/or develop new Services;
            </li>
            <li>
              To allow us and our advertising partners to personalize the content and advertising
              that you see on the Services or other websites and mobile applications (see{' '}
              <a href='#personalizedContent'>Personalized Content and Advertising section below</a>{' '}
              ;
            </li>
            <li>
              To protect the safety of all who use our Service and maintain security at event venues
              and with respect to our Services;
            </li>
            <li>To enforce the legal or contractual terms that govern your use of the Services;</li>
            <li>To administer and troubleshoot the Services; and</li>
            <li>
              Subject to applicable law, to disclose your information to reputable third parties who
              may provide you with information about their products and/or services that may be of
              interest to you.
            </li>
          </ul>
          <p>
            We may also combine information that we collect from you with information we obtain
            about you from other members of the MSG Family and/or non-affiliated third parties.
          </p>
          <p>
            Subject to applicable law, we may also use the information collected from the{' '}
            <a href='#infoCollect'>“Information We Collect”</a> section, such as device identifiers
            and MAC addresses, to associate users’ activities, customize content, or tailor
            advertising to those users across different devices and browsers (for instance, tablets,
            mobile devices and applications, and websites).
          </p>
          <p>
            We may aggregate and/or de-identify any information collected through the Services. We
            may use de-identified and/or aggregated data for any purpose, including without
            limitation for research and marketing purposes, and may also disclose such data to any
            third parties, including without limitation, advertisers, promotional partners,
            sponsors, event promoters, and/or others.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='whenDiscloseInfo'>
            3. WHEN WE DISCLOSE YOUR INFORMATION
          </h3>
          <p>
            We may disclose your information to any agent or company that is part of the MSG Family
            for any of the purposes listed in the “How We Use Your Information” section. The MSG
            Family may disclose your information to non-affiliated third parties under the following
            circumstances.
          </p>
          <p className='ml-3'>
            <span className='bold weight-600'>A. Consent.</span> If you consent, we may disclose
            your information to non-affiliated third parties) in order to provide services or
            products that you have requested and/or services that we believe will interest you.
          </p>
          <p className='ml-3'>
            <span className='bold weight-600'>B. Service Providers.</span> We may provide access to
            your information to select third parties who perform services on our behalf and for any
            other legitimate business and operational purposes. These third parties provide a
            variety of services to us, including, without limitation, ticket processing, billing,
            sales, marketing, advertising, market research, fulfillment, data storage, analysis and
            processing, and legal services.
          </p>
          <p className='ml-3'>
            <span className='bold weight-600'>C. Other Third Parties.</span>Except as provided
            herein, we may disclose your information to reputable third parties so that they may
            contact you to offer products, services and promotions, such as sweepstakes and contests
            that you may find valuable. We may also disclose your information to third party
            advertising networks or other parties with whom we have a contractual relationship. You
            can instruct us not to provide your contact information to these third parties by
            sending an email to &nbsp;
            <a href='mailto:privacychoices@msg.com'>privacychoices@msg.com</a> with “REMOVE THIRD
            PARTIES” in the subject line. If you receive a communication from a third party and you
            do not wish to receive future communications from that party, you should also tell them
            to remove you from their marketing list. For European Economic Area (“EEA”) residents
            that we can reasonably identify, we will obtain your consent before sharing your
            information with third parties for these marketing purposes.
          </p>
          <p>
            We may also allow third-party advertising technologies (e.g., ad networks and ad
            servers) on our Services that use cookies and similar technologies to deliver relevant
            and targeted content and advertising to you about the Services on other websites you
            visit and applications you use, as explained further in{' '}
            <a href='#personalizedContent'>“Personalized Content and Advertising”</a> below.
          </p>
          <p className='ml-3'>
            <span className='bold weight-600'>D. Protection of MSG Family and Others.</span>
            By using the Services, you acknowledge, consent and agree that we may access, preserve
            and disclose your information, including, but not limited to, any user content, if
            required or permitted to do so by law or in a good faith belief that such access,
            preservation or disclosure is reasonably necessary to: (a) comply with legal process;
            (b) enforce our <a href='https://www.msgentertainment.com/terms'>Terms of Use</a> ,this
            Policy, or other contracts with you; (c) respond to claims that any content violates the
            rights of third parties; (d) respond to your requests for customer service; and/or (e)
            protect the rights, property or personal safety of the MSG Family, its agents and
            affiliates, its users and/or the public. We may also disclose information to law
            enforcement agencies in emergency circumstances where the disclosure of such information
            is consistent with the types of emergency disclosures permitted or required by law.
          </p>
          <p className='ml-3'>
            <span className='bold weight-600'>E. Business Transfers.</span> Each data controller
            reserves the right to disclose and transfer all of your information, including your
            contact information, in connection with a proposed (including due diligence) or actual
            merger, acquisition, transfer of control, distribution or sale of all, or components, of
            its business.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='legalBases'>
            4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OUR LEGAL BASES FOR PROCESSING YOUR
            PERSONAL DATA{' '}
          </h3>
          <p>
            The laws in some jurisdictions require companies to tell you about the legal ground they
            rely on to use or disclose your “personal data” as such term is defined under applicable
            law. To the extent that those laws apply, our legal grounds are as follows:
          </p>
          <ul>
            <li>
              To honor our contractual commitments to you: Much of our processing of personal data
              is to meet our contractual obligations to our customers, or to take steps at
              customers’ requests in anticipation of entering into a contract with them. For
              example, we handle personal data on this basis to provide you with tickets to events.
            </li>
            <li>
              Consent: Where required by law, and in some other cases, we handle personal data on
              the basis of your implied or express consent.
            </li>
            <li>
              Legitimate interests: In many cases, we handle personal data on the ground that it
              furthers our legitimate interests in ways that are not overridden by the interests or
              fundamental rights and freedoms of the affected individuals:
              <p>
                <ol className='ml-3'>
                  <li>Customer service</li>
                  <li>Marketing</li>
                  <li>Protecting our customers, personnel, and property</li>
                  <li>Analyzing and improving our business</li>
                  <li>Managing legal issues</li>
                </ol>
              </p>
            </li>
            <li>
              We may also process personal data for the legitimate interests of our business
              partners, such as to assist with marketing.
            </li>
            <li>
              Legal compliance: We need to use and disclose personal data in certain ways to comply
              with our legal obligations.
            </li>
            <li>
              To protect the vital interests of the individual or others: For example, we may
              collect or disclose personal data to help resolve an urgent medical situation.
            </li>
          </ul>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='internationalTransfer'>
            5.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INTERNATIONAL TRANSFER
          </h3>
          <p>
            The Services are operated, offered, and provided in the United States and are governed
            by United States law. If you are a resident of the European Union or other location
            outside the United States, please be advised that any information you provide through
            the Services will be transferred to the United States or other designated locations
            outside of the United States for certain processing/hosting operations. We have taken
            commercially reasonable steps to ensure that your personal information is appropriately
            protected and is processed only in accordance with this Policy.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='personalizedContent'>
            6.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PERSONALIZED CONTENT AND ADVERTISING
            AND YOUR CHOICES
          </h3>
          <p className='ml-8'>
            <span className='bold weight-600'>A. Online Analytics .</span>
          </p>
          <p>
            We may use third-party web analytics services on our Services, such as those of Google
            Analytics. These service providers use the sort of technology previously described in
            the <a href='#infoCollect'>“Information We Collect”</a> section to help us analyze how
            users use the Services including by noting the third-party website from which you
            arrive. The information (including your IP address) collected by the technology will be
            disclosed to or collected directly by these service providers, who use the information
            to evaluate your use of the Services. We also use Google Analytics for certain purposes
            related to advertising, as described in the following section. To prevent Google
            Analytics from using your information for analytics, you may install the Google
            Analytics Opt-out Browser Add-on by clicking{' '}
            <a href='http://tools.google.com/dlpage/gaoptout'>here</a>.{' '}
          </p>
          <p className='ml-8'>
            <span className='bold weight-600'>B. Tailored Advertising .</span>
          </p>
          <p>
            We or our third party advertising partners may use technologies such as cookies, pixels
            and similar tools that allow for the delivery of relevant content and advertising on
            other websites or mobile applications you visit and/or use. Such content and advertising
            may be about the Services or based on your activity on the Services. The ads may be
            based on various factors such as the content of the page you are visiting, information
            you provide such as your age and gender, your searches, demographic data, user-generated
            content, and other information we collect from you. These ads may be based on your
            current activity or your activity over time and may be tailored to your interests.
          </p>
          <p>
            Also, third parties and partners use cookies or other tracking technologies on your
            computer, mobile phone, or other device to collect information about your use of the
            Services in order to (i) inform, optimize, and serve ads based on past visits to our
            websites and other sites and (ii) report how our ad impressions, other uses of ad
            services, and interactions with these ad impressions and ad services are related to
            visits to our websites and use of our Services. We also may disclose a hashed version of
            your email address with third-party advertising partners, and content that you post
            publicly when using the Services (e.g. user-generated content) for purposes of
            delivering tailored advertising to you when you use services provided by those
            third-part advertising partners and/or you visit other websites and/or use mobile
            applications. Those parties that use these technologies may offer you a way to opt out
            of ad targeting as described below. You may receive tailored advertising on your
            computer through a web browser. If you are interested in more information about tailored
            browser advertising and how you can generally control cookies from being put on your
            computer to deliver tailored advertising, you may visit the{' '}
            <a href='http://www.networkadvertising.org/choices'>
              Network Advertising Initiative’s Consumer Opt-Out link
            </a>{' '}
            , the
            <a href='http://www.aboutads.info/choices/'>
              Digital Advertising Alliance’s Consumer Opt-Out link
            </a>
            <a href='http://preferences-mgr.truste.com/'>TRUSTe’s Advertising Choices Page</a> or to
            opt-out of receiving tailored advertising from companies that participate in those
            programs.
          </p>
          <p>
            To opt out of Google Analytics for display advertising or to customize Google display
            network ads, you can visit the
            <a href='https://www.google.com/settings/ads'>Google Ads Settings page</a> . Please note
            that to the extent advertising technology is integrated into the Services, you may still
            receive advertisements even if you opt out of tailored advertising. In that case, the
            ads will just not be tailored. Also, we do not control any of the above opt-out links
            and are not responsible for any choices you make using these mechanisms or the continued
            availability or accuracy of these mechanisms.
          </p>
          <p>
            When using a mobile application, you may also receive tailored in-application
            advertisements. Each operating system, iOS for Apple phones, Android for Android devices
            and Windows for Microsoft devices provides its own instructions on how to prevent the
            delivery of tailored in-application advertisements. You may review the support materials
            and/or the privacy settings for the respective operating systems in order to opt-out of
            tailored in-application advertisements. For any other devices and/or operating systems,
            please visit the privacy settings for the applicable device or contact the applicable
            platform operator. We do not control how the applicable platform operator allows you to
            control receiving personalized in-application advertisements; thus, you should contact
            the platform provider for further details on opting out of tailored in-application
            advertisements.
          </p>
          <p>
            If you use different devices (e.g., home or work computer, laptop, table, mobile phone,
            connected television or other Internet-connected device) to access our Services, we may
            be able to associate you with each of your devices through our participation in the
            Adobe Marketing Device Co-Op. This service allows us to better understand how you use
            our Services across the various devices that you use, and to deliver you tailored
            advertisements. You can visit
            <a href='https://cross-device-privacy.adobe.com/'>here</a> to learn more about how Adobe
            does this and to manage your choices relating to this linking of devices.
          </p>
          <p>
            Please note that to the extent advertising technology is integrated into the Services,
            you may still receive advertisements even if you opt-out. In that case, the advertising
            will not be tailored to your interests.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='dataRights'>
            7.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;YOUR DATA RIGHTS AND CHOICES
          </h3>
          <p className='ml-8'>
            <span className='bold weight-600'>A. Marketing Communications .</span>
          </p>
          <p>
            You can unsubscribe from marketing emails we send to you by clicking the “unsubscribe”
            link they contain. If you have provided contact information while using the Services and
            later decide that you would rather not have the MSG Family use that information for
            marketing purposes in accordance with this Policy, you can also opt out by sending us an
            email at <a href='mailto:privacychoices@msg.com '>privacychoices@msg.com</a> (including
            your name and email address) and “Marketing Opt-Out” in the subject line. For clarity,
            individuals in the EEA and the United Kingdom have the right (subject to verification
            and authentication) to opt out of all of our processing of their personal data for
            direct marketing purposes. To exercise this right, they may contact us as at{' '}
            <a href='mailto:privacychoices@msg.com '>privacychoices@msg.com</a> with “EEA/UK
            Opt-Out” in the subject line. Please note that we may send you certain transactional and
            informational emails based on services you have requested, such as booking an event and
            you may not opt-out of those emails.
          </p>
          <p className='ml-8'>
            <span className='bold weight-600'>B. Rights Regarding Your Information ine .</span>
          </p>
          <p>
            Depending on your jurisdiction, you may have the right, in accordance with applicable
            data protection laws, to make requests related to your “personal information” or
            “personal data” (as such terms are defined under applicable laws, and collectively
            referred to herein as “personal data”). Specifically, you may have the right to ask us
            to:
          </p>
          <ul className='ml-8'>
            <li>
              Inform you about the categories of personal information we collect or disclose about
              you; the categories of sources of such information; the business or commercial purpose
              for collecting your personal information; and the categories of third parties with
              whom we disclose personal information.
            </li>
            <li>
              Provide you access to and/or a copy of certain personal information we hold about you.
            </li>
            <li>Correct or update personal information we hold about you.</li>
            <li>Delete certain personal information we have about you.</li>
            <li>
              Provide you with information about the financial incentives that we offer to you, if
              any.
            </li>
            <li>Restrict or object to certain uses of your information.</li>
            <li>
              Opt you out of the processing of your personal information for purposes of profiling
              in furtherance of decisions that produce legal or similarly significant effects, if
              applicable.
            </li>
          </ul>

          <p>
            You may also have the right to opt out of “sales” of your information and
            “sharing/processing of your information for targeted advertising” as described below. If
            you are a California resident, please see the “Notice to California Residents” section
            below for more information about our privacy practices and your rights as a California
            resident.
          </p>
          <p>
            As provided in applicable law, you also have the right to not be discriminated against
            for exercising your rights. Please note that certain information may be exempt from such
            requests under applicable law. For example, we need to retain certain information in
            order to provide our services to you. We also need to take reasonable steps to verify
            your identity before responding to a request, which may include, at a minimum, depending
            on the sensitivity of the information you are requesting and the type of request you are
            making, verifying your name and email address. To exercise any of these rights, please
            email us at <a href='mailto:privacychoices@msg.com '>privacychoices@msg.com</a> with
            your name and type of request you are making or fill out the form here. You can also
            exercise your right to update and/or correct certain information collected by the MSG
            Family by visiting your account page(s). If a member name and password are required to
            access the feature, you will need to provide such information to update your
            registration information. If you have forgotten your password, you may obtain it by
            visiting the registration area and requesting that a hint be sent to you at the email
            address you originally provided during registration.
          </p>
          <p>
            Depending on applicable law, you may have the right to appeal our decision to deny your
            request, if applicable. To exercise such an appeal right, please email us at{' '}
            <a href='mailto:privacychoices@msg.com '>privacychoices@msg.com</a>. You also have the
            right to lodge a complaint with a supervisory authority.
          </p>
          <p className='ml-8'>
            <span className='bold weight-600'>
              C. Notice of Right to Opt Out of Sales of Personal Information and Processing/Sharing
              of Personal Information for Targeted Advertising Purposes .
            </span>
          </p>
          <p>
            Depending on your jurisdiction, you may also have the right to opt out of “sales” of
            your information and “sharing/processing of your information for targeted advertising.”
          </p>
          <p>
            As explained in the “When We Disclose Your Information” section above, we sometimes
            disclose information to unaffiliated third parties we collaborate with or that provide
            offers that we think may be of value to you. We also provide personal information to
            third-party advertising providers for targeted advertising purposes. Under applicable
            law, the disclosure of your personal information to these third parties to assist us in
            providing these services may be considered a “sale” of personal information or the
            processing/sharing of personal information for targeted advertising purposes.
          </p>
          <p>
            If you would like to opt out of the disclosure of your personal information for purposes
            that could be considered “sales” for those third parties&apos; own commercial purposes,
            or “sharing” or processing for purposes of targeted advertising, please visit the
            following page: Do Not Sell or Share My Personal Information.
          </p>
          <p>
            Depending on your jurisdiction, you may be permitted to designate an authorized agent to
            submit such requests on your behalf. Please note that we do not knowingly sell the
            personal information of minors under 16 years of age.
          </p>
          <p className='ml-8'>
            <span className='bold weight-600'>D. App Notifications and Location Data .</span>
          </p>
          <p>
            To view and manage notifications and preferences relating to our apps, including certain
            options regarding the collection and use of location data, visit the settings menu of
            the app and of your operating system.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='noticeCalifornia'>
            8.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NOTICE TO CALIFORNIA RESIDENTS
          </h3>
          <p>
            If you are a California resident, California law requires us to provide you with some
            additional information regarding how we collect, use, and disclose your “personal
            information” as defined in the California Consumer Privacy Act (“CCPA”).
          </p>
          <p>
            We describe the categories of information we collect, our business purposes for
            collecting such information, the sources and uses of such information and the entities
            with which we disclose such information in the{' '}
            <a href='#infoCollect'>“Information We Collect”</a> ,
            <a href='#howUseInfo'>“How We Use Your Information,”</a> and{' '}
            <a href='#whenDiscloseInfo'>“When We Disclose Your Information”</a> sections of this
            Privacy Policy. We provide additional information required by the CCPA below.
          </p>
          <p className='heading italic'>
            A. &nbsp;&nbsp;&nbsp;Categories of personal information we collect, use and disclose.{' '}
          </p>
          <p>
            Throughout this Policy, we discuss in detail the types of Information we collect from
            and about users and discuss how we use and disclose such information. The following are
            the “categories” of personal information under the CCPA that we collect from California
            consumers and that we may, as discussed throughout this Policy, use and disclose for our
            business purposes:
          </p>
          <p className='ml-8'>
            Identifiers (such as name, address, email address); commercial information (such as
            transaction data); financial data (such as credit card information); device identifiers
            (such as IP address and unique device identifiers); internet or other network or device
            activity (such as browsing history or app usage); general geolocation data from IP
            addresses and precise geolocation information when you use an applicable MSG Family app;
            biometric information that may be collected when visiting one of our venues; audio or
            visual information; physical characteristics, such as if you voluntarily submit a photo
            or video; characteristics of potentially protected classifications, such as gender, and
            marital status; any user-generated content or feedback that you provide; professional or
            employment related data; and other information that identifies or can be reasonably
            associated with you.
          </p>
          <p className='heading italic'>
            B. &nbsp;&nbsp;&nbsp;How we use these categories of personal information.{' '}
          </p>
          <p>
            We and our service providers may use the categories of personal information we collect
            from and about you for the following business and commercial purposes (as those terms
            are defined in applicable law).
          </p>
          <ul>
            <li>Our or our service providers’ operational purposes;</li>
            <li>Auditing consumer interactions on our site (e.g., measuring ad impressions);</li>
            <li>
              Detecting, protecting against, and prosecuting security incidents and fraudulent or
              illegal activity;
            </li>
            <li>Bug detection and error reporting;</li>
            <li>
              Customizing content that we or our service providers display on the Services (e.g.,
              contextual ads);
            </li>
            <li>
              Providing the Services (e.g., account servicing and maintenance, customer service,
              advertising and marketing, analytics, and communication about the Services);
            </li>
            <li>
              Improving our existing Services and developing new services (e.g., by conducting
              research to develop new products or features);
            </li>
            <li>
              Other uses that advance our commercial or economic interests, such as third-party
              advertising and communicating with you about relevant offers from third party
              partners;
            </li>
            <li>Other uses about which we notify you.</li>
          </ul>
          <p>
            Examples of these types of uses are discussed in our main privacy policy in the{' '}
            <a href='#howUseInfo'>“How We Use Your Information”</a> section. We may also use the
            categories of personal information for compliance with applicable laws and regulations,
            and we may combine the information we collect (“aggregate”) or remove pieces of
            information (“de-identify”) to limit or prevent identification of any user or device.
          </p>
          <p className='heading italic'>
            C. &nbsp;&nbsp;&nbsp;Categories of personal information we disclose for business
            purposes
          </p>

          <p>
            We may disclose the following categories of information about you or your use of our
            Services to service providers for business purposes (as defined by applicable law) or as
            required by applicable law:
          </p>
          <p className='ml-3'>
            Identifiers (such as name, address, email address); commercial information (such as
            transaction data); financial data (such as credit card information); device identifiers
            (such as IP address and unique device identifiers); internet or other network or device
            activity (such as browsing history or app usage; general geolocation data from IP
            addresses and precise geolocation information when you use an applicable MSG Family app;
            biometric information that may be collected when you visit one of our venues; audio or
            visual information; physical characteristics, such as if you voluntarily submit a photo
            or video; characteristics of potentially protected classifications, such as gender, and
            marital status; any user-generated content or feedback that you provide; professional or
            employment related data; categories of information considered “sensitive” under
            California law (such as precise geolocation, biometric information, and
            username/password); and other information that identifies or can be reasonably
            associated with you.
          </p>
          <p>
            We may also disclose this information to third parties for legal, compliance or security
            purposes, or in connection with a business transfer, as described further in “When We
            Disclose Your Information” above.
          </p>
          <p className='heading italic'>
            D. &nbsp;&nbsp;&nbsp;Sale/Sharing of personal information.
          </p>
          <p>
            The CCPA sets forth certain obligations for businesses that “sell” personal information
            or “share” personal information for cross-context behavioral advertising purposes. Under
            the CCPA, “sale” and “sharing” are defined such that they may include allowing third
            parties to receive certain information for advertising purposes. We “sell” or “share”
            the following categories of information to third-party advertising partners and vendors
            that support our advertising efforts (such as advertising analytics services):
          </p>
          <p className='ml-3'>
            Identifiers (such as name, address, email address); commercial information (such as
            transaction data); internet or other network or device activity (such as browsing
            history or app usage; general geolocation data from IP addresses and precise geolocation
            information when you use an applicable MSG Family app.
          </p>
          <p>
            Advertising, including targeted advertising, enables us to provide you certain content
            for free and allows us to provide you offers relevant to you. If you would like to opt
            out of our use of your information for such purposes that are considered a “sale” or
            “sharing” for cross-context behavioral advertising purposes under California law, you
            may do so as outlined on the following page: Do Not Sell or Share My Personal
            Information. Please note that we do not knowingly sell the personal information of
            minors under 16 years of age.
          </p>
          <p className='heading italic'>E. &nbsp;&nbsp;&nbsp;California Privacy Rights. </p>
          <p>
            California residents can make certain requests about their personal information under
            the CCPA. Specifically, if you are a California resident, you may request that we:
          </p>
          <ul>
            <li>
              provide you with information about: the categories of personal information we collect,
              disclose or sell about you; the categories of sources of such information; the
              business or commercial purpose for collecting or selling your personal information;
              and the categories of third parties to whom we disclose personal information. Such
              information is also set forth in this Privacy Policy;{' '}
            </li>
            <li>provide access to and/or a copy of certain information we hold about you;</li>
            <li>correct inaccurate personal information we hold about you;</li>
            <li>delete certain information we have about you; and/or</li>
            <li>
              provide you with information about the financial incentives that we offer to you, if
              any.
            </li>
          </ul>
          <p>
            Please note, in the limited circumstances that we process sensitive personal information
            as defined by the CCPA, we do not use or disclose it other than for disclosed and
            permitted business purposes for which there is not a right to limit under the CCPA.
          </p>
          <p>
            California residents can also designate an authorized agent to make access and/or
            deletion requests on their behalf. In order for an authorized agent to be verified, you
            must provide the authorized agent with signed, written permission to make such requests
            or a power of attorney. We may also follow up with you to verify your identity before
            processing the authorized agent’s request.
          </p>
          <p>
            We will take reasonable steps to verify your identity before responding to a request for
            access and/or deletion from you or your designated agent. If we are unable to verify
            you, we may be unable to respond to your requests.
          </p>
          <p>
            The CCPA further provides you with the right to not be discriminated against (as
            provided for in applicable law) for exercising your rights.
          </p>
          <p>
            Please note that certain information may be exempt from such requests under California
            law. For example, we need certain information in order to provide the Services to you.
          </p>
          <p>
            If you are a California resident and would like to exercise any of your legal rights
            under the CCPA, please use our online consumer request form &nbsp;
            <a href='https://msgtechnology.formstack.com/forms/consumerrequest'>here</a> or contact
            us at <a href='mailto:privacychoices@msg.com'>privacychoices@msg.com</a> with “CCPA
            Rights” in the subject line.
          </p>
          <p className='heading italic'>F. &nbsp;&nbsp;&nbsp;Shine the Light. </p>
          <p>
            California Law permits customers who are California residents to request certain
            information once per year regarding our disclosure of “personal information” (as that
            term is defined under applicable California law) to third parties for such third
            parties’ direct marketing purposes. To make such a request, please send an email to{' '}
            <a href='mailto:privacychoices@msg.com'>privacychoices@msg.com</a> with “REMOVE Third
            Parties” in the subject line.
          </p>
          <p className='heading italic'>
            G. &nbsp;&nbsp;&nbsp;Additional California Rights for Minors
          </p>
          <p>
            If you are a California resident under the age of 18 and are a registered User of our
            Services, you may request that we remove from our Services any content you post to our
            Services that can be accessed by any other User (whether registered or not). Please note
            that any content that is removed from our Services may remain on our servers and in our
            systems. To request removal of content under this provision, please send an email to{' '}
            <a href='mailto:privacychoices@msg.com'>privacychoices@msg.com</a> with “California
            Minors Opt-Out” in the subject line and provide us with a description of the content and
            the location of the content on our Services, and any other information that we may
            require in order to consider your request. Please note that removal of content under
            this provision does not ensure complete or comprehensive removal of the content or
            information posted on the Services by you.
          </p>
          <p className='heading italic'>
            H. &nbsp;&nbsp;&nbsp;California Online Privacy Protection Act Notice Concerning Do Not
            Track Signals
          </p>
          <p>
            Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers.
            DNT is a way for users to inform websites and services that they do not want certain
            information about their webpage visits collected over time and across websites or online
            services. We are committed to providing you with meaningful choices about the
            information collected on the Services for third party purposes, and that is why we
            provide links to the following optout pages:{' '}
            <a href='http://www.networkadvertising.org/choices'>
              Network Advertising Initiative’s Consumer Opt-Out link
            </a>{' '}
            , the{' '}
            <a href='http://www.aboutads.info/choices/'>
              Digital Advertising Alliance’s Consumer Opt-Out link
            </a>{' '}
            or &nbsp;
            <a href='http://preferences-mgr.truste.com/'>TRUSTe’s Advertising Choices Page</a> , as
            well as choices provided by Google Ads and Google Analytics. However, we do not
            recognize or respond to browser-initiated DNT signals, except for legally required
            browser based signals, as the Internet industry is currently still working toward
            defining exactly what DNT means, what it means to comply with DNT, and a common approach
            to responding to DNT. To learn more about Do Not Track, you can do so{' '}
            <a href='http://allaboutdnt.com/'>here</a> .
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='privacyInfo'>
            9.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PRIVACY INFORMATION FOR NEVADA
            RESIDENTS
          </h3>
          <p>
            Under Nevada law, certain Nevada consumers may opt out of the sale of “covered
            information” for monetary consideration. We do not sell “covered information as defined
            under Nevada law; however, if you are a Nevada resident who has purchased services from
            us, you may submit a request to opt out of any potential future sales here . Please note
            we may take reasonable steps to verify your identity and the authenticity of the
            request. Once verified, we will maintain your request in the event that our practices
            change.{' '}
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='links'>
            10.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LINKS{' '}
          </h3>
          <p>
            Links to third-party websites, applications and other services may be made available
            through the Services. We are not responsible for the privacy practices or the content of
            such third-party services. Any information you provide via those services is subject to
            the applicable privacy policies and is not covered by this Policy.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='security'>
            11.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SECURITY AND RETENTION OF YOUR
            INFORMATION
          </h3>
          <p>
            We have implemented administrative, technical, and physical security measures to protect
            against the loss, misuse and/or alteration of your information. These safeguards vary
            based on the sensitivity of the information that we collect and store. However, we
            cannot and do not guarantee that these measures will prevent every unauthorized attempt
            to access, use, or disclose your information since despite our efforts, no Internet
            and/or other electronic transmissions can be completely secure.
          </p>
          <p>
            We generally retain personal data for so long as it may be relevant to the purposes
            above. In determining how long to retain information, we consider the amount, nature and
            sensitivity of the information, the potential risk of harm from unauthorized use or
            disclosure of the information, the purposes for which we process the information,
            applicable legal requirements, and our legitimate interests. The purposes for which we
            process information (as well as the other factors listed above) may dictate different
            retention periods for the same types of information. For example, we retain your email
            address as an authentication credential (where applicable) as long as you have an
            account with us and an additional period of time after that for our legitimate interests
            and for our fraud and legal compliance purposes. If you opt out of email marketing, we
            maintain your email on our suppression list for an extended time to comply with your
            request. To dispose of your information, we may anonymize it, delete it or take other
            appropriate steps. Data may persist in copies made for backup and business continuity
            purposes for additional time.
          </p>
        </div>

        <div className='body-copy'>
          <h3 className='heading' id='infoChildren'>
            {' '}
            12.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INFORMATION FROM CHILDREN
          </h3>
          <p>
            The Services are not directed to children under the age of 16. If we discover we have
            received any “personal information” (as defined under the Children’s Online Privacy
            Protection Act) from a child under the age of 13 in violation of this Policy, we will
            take reasonable steps to delete that information as quickly as possible. If you believe
            the MSG Family has any information from or about anyone under the age of 16, please
            contact us at the address listed below.
          </p>
          <p>
            For residents of the EEA, where processing of personal information is based on consent,
            the MSG Family will not knowingly engage in that processing for users under the age of
            consent established by applicable data protection law. If we learn that we are engaged
            in that processing with such users, we will halt such processing and will take
            reasonable measures to promptly remove applicable information from our records.
          </p>
        </div>

        <div className='body-copy' id='contactUs'>
          <h3 className='heading'>
            13.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONTACTING US ABOUT THIS POLICY
          </h3>
          <p>
            Please do not hesitate to contact us with any questions, concerns, requests or comments
            by email at
            <a href='mailto:msgfeedback@msg.com'>msgfeedback@msg.com</a>
          </p>
        </div>

        <div className='body-copy' id='changePolicy'>
          <h3 className='heading'>
            14.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CHANGES TO THIS POLICY
          </h3>
          <p>
            We reserve the right to change this Policy at any time to reflect changes in the law,
            our data collection and use practices, the features of our Services, or advances in
            technology. Please check this page periodically for changes. Your continued use of the
            Services following the posting of changes to this Policy will be deemed to mean you
            understand the applicability of those changes.{' '}
          </p>
        </div>

        <div className='body-copy' id='dataController'>
          <h3 className='heading'>
            15.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DATA CONTROLLER CONTACT INFORMATION
          </h3>
          <p>
            <span>MSG Entertainment Holdings, LLC</span>
            <br />
            <span>2 Pennsylvania Plaza</span>
            <br />
            <span>New York, New York 10121</span>
            <br />
            <span></span>
            <br />
            <span>MSG Sports, LLC</span>
            <br />
            <span>2 Pennsylvania Plaza</span>
            <br />
            <span>New York, New York 10121</span>
            <br />
            <span></span>
            <br />
            <span>Sphere Entertainment Group, LLC</span>
            <br />
            <span>2 Pennsylvania Plaza</span>
            <br />
            <span>New York, New York 10121</span>
            <br />
            <span></span>
            <br />
            <span></span>
            <br />
            <span>Effective Date: April 20, 2023</span>
            <br />
            <span></span>
            <br />
            <span>
              © 2023 MSG Entertainment Holdings, LLC MSG Sports, LLC, and Sphere Entertainment
              Group, LLC, 2 Penn Plaza, New York, NY 10121. All rights reserved. Use of any Service
              which includes a link to this Policy signifies your acceptance of this Policy.
            </span>
            <br />
            <span></span>
            <br />
            <span>Thank you for visiting!</span>
            <br />
          </p>
        </div>
      </div>
    </section>
  )
}
