import React from 'react';
import './policy.scss'
import { useNavigate } from 'react-router-dom';
import { updatePolicy } from '../../api/policy';
import { usePolicyContext } from '../../context/Policy';

const Policy: React.FC= () => {
    const { policy, setPolicy } = usePolicyContext()
    const navigate = useNavigate()
    function createMarkup(htmlString: string) {
        return { __html: htmlString };
    }

    const onAccept = async () => {
        const response = await updatePolicy({ policyUid: policy.article.uid })
        if (!response.error) {
            window.scrollTo(0, 0);
            navigate('/')
        }
        else{
            navigate('/login')
        }
    }
    const onDecline = () => {
        localStorage.removeItem('userInfo');
        navigate('/login')
    };


    return (
        <div className='terms-of-use'>
            <div dangerouslySetInnerHTML={createMarkup(policy?.article?.content || '')} />
            <div className='policy__btn__wrapper'>
                <button className='btn btn-danger' onClick={onDecline}>Decline</button>
                <button className='btn btn-primary' onClick={onAccept}>Accept</button>
            </div>
        </div>
    );
};

export default Policy;
