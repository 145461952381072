import LogoutButton from '../../../Button/LogoutButton'
import { NavItem } from '../../Desktop/NavItem'
import { Policies } from '../constant'

export const DropDown = () => {
  return (
    <div className='mob-feature-nav'>
      <ul className='nav flex-column'>
        <>
          {Policies.map((item, index) => {
            return <NavItem {...item} key={index} onClick={() => null} isSelected={false} />
          })}
          <li className='nav-item logout'>
            <LogoutButton />
          </li>
        </>
      </ul>
    </div>
  )
}
