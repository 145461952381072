import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { makeProtectedRoutes } from '../../hoc/makeProtected'
import Category from '../Category'
import './About.scss'

const AboutPage = () => {

// category page is reused and called with the article name
  return (<Category articleName='about_page'/>
  )
}

export const About = makeProtectedRoutes(AboutPage)
