import { CategoryInfo } from '../Category'

export const CategoryLinks = (props: CategoryLinks) => {
  const { handleclick, info } = props
  const parser = new DOMParser()

  return (
    <section
      className='navigational-elements'
      style={{
        backgroundColor: info.navListBackground ?? '#070707',
        color: info.navListForegroundColor ?? '#000000',
      }}
    >
      <p className='description'>{parser?.parseFromString(info?.description, 'text/html')?.body?.textContent}</p>
      <ul className='navigational-links'>
        {info?.subtopics?.map((item: any, index: number) => {
          return (
            <li key={index} onClick={() => handleclick(item?.uid)}>
              {parser?.parseFromString(item?.title, 'text/html')?.body?.textContent}
              <i className='bi bi-arrow-right-short'></i>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
type CategoryLinks = {
  handleclick: CallableFunction
  info: CategoryInfo
}
