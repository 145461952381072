import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import { useLoaderContext } from '../../../../context/LoaderContect';

export const SubcategoryNavigator = (props: SubcategoryType) => {
    // const { handleClick, previous, next } = props;
    const { subId, id } = useParams()
    const { data } = props;
    const { isLoading } = useLoaderContext()
    const parser = new DOMParser()
    const current = data?.findIndex((item: any) => item.uid == subId)
    const previous = data[current - 1]
    const next = data[current + 1]
    const navigate = useNavigate()

    const handleClick = (subCatId: string) => {
        navigate(`/category/${id}/subcategory/${subCatId}`)
    }
    return (
        <section className="core-tech-section">
            <div className="layer-content">
                {
                        <>
                            <h5 onClick={() => handleClick(previous.uid)}>
                                {previous &&
                                    <><i className="bi bi-arrow-left-short"></i>
                                    {parser?.parseFromString(previous?.title, 'text/html')?.body?.textContent}
                                    </>
                                }
                            </h5>
                            <h5 onClick={() => handleClick(next.uid)}>
                                {next &&
                                    <>{parser?.parseFromString(next?.title, 'text/html')?.body?.textContent} <i className="bi bi-arrow-right-short"></i></>
                                }
                            </h5>
                        </>
                }
            </div>
        </section>
    )
}

type SubcategoryType = {
    // next: any,
    // previous: any,
    // handleClick: CallableFunction
    data: any
}