import axios from 'axios'
import { END_POINTS } from './domains'

export const UserLogIn = async (email: string, password: string) => {
  try {
    const result: any = await axios.post(END_POINTS.LOG_IN, { email, password })
    return {
      ...result,
      error: false,
    }
  } catch (error) {
    console.log(error);
    
    return {
      error: true,
    }
  }
}

export const Logout = async () => {
  try {
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')))

    await axios.delete(END_POINTS.LOG_OUT, {
      headers: {
        Authorization: userInfo?.token,
        sessionId: userInfo.sessionId,
      },
    })
  } catch (error) {
    return {
      error: true,
    }
  }
}

export const updatePolicyAcceptedStatus = async (email: string) => {
  try {
    const result: any = await axios.post(END_POINTS.POLICY_UPDATE, { email: email })
    return {
      ...result,
      error: false,
    }
  } catch (error) {
    return {
      error: true,
    }
  }
}
