import Router from './router'
import './App.scss'
import { SubCategoryContextProvider } from './context/Context'
import './style/style.scss'
import { LoaderContextProvider } from './context/LoaderContect'
import { TopicContextProvider } from './context/Topic'
import { AuthContextProvider } from './context/Auth'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './pages/Home/Content/content.scss'
import './pages/Home/Features/features.scss'
import './pages/About/About.scss'
import { useEffect } from 'react'
import { useRestrictCopyPaste } from './hooks/UseRestrictCopyPaste'
import './style/custom.scss'
import { CategoryContextProvider } from './context/Categories'

function App() {
  useRestrictCopyPaste({ window, actions: ['copy', 'cut', 'paste', 'contextmenu'] })

  const preventDevTools = (e: KeyboardEvent) => {
    if (
      (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl + Shift + I
      (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl + Shift + j
      (e.ctrlKey && e.shiftKey && e.keyCode === 67) || // Ctrl + Shift + C
      e.keyCode === 123 // f12
    ) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', preventDevTools)
    return () => {
      window.removeEventListener('keydown', preventDevTools)
    }
  }, [])

  // Make Api call to get the routes
  return (
    <AuthContextProvider>
      <CategoryContextProvider>
        <TopicContextProvider>
          <LoaderContextProvider>
            <SubCategoryContextProvider>
              <Router />
            </SubCategoryContextProvider>
          </LoaderContextProvider>
        </TopicContextProvider>
      </CategoryContextProvider>
    </AuthContextProvider>
  )
}

export default App
