import $ from 'jquery'
import 'owl.carousel'

export const serverMessage = 'Something Went Wrong Please Try Again !!'
export const validationMessage = 'Please Provide Required Fileds'
export const footage = 'footage'
export const category = 'category'

export const carouselScript = () => {
  $('#carouselSlider').ready(function () {
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 2,
          nav: false,
        },
        1024: {
          items: 3,
          nav: true,
          loop: false,
        },
      },
    })
  })
}
