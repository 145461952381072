import React from 'react'
import { useTranslation } from 'react-i18next'

export const FootageCards: React.FC<FootageCards> = ({
  img,
  onClick,
  title,
  discription,
  tags,
}) => {
  const { t } = useTranslation()
  const parser = new DOMParser()

  const formattedImg = parser?.parseFromString(img, 'text/html')?.body?.textContent || ''
  const formattedTitle = parser?.parseFromString(title, 'text/html')?.body?.textContent || ''
  const formattedDescription =
    parser?.parseFromString(discription, 'text/html')?.body?.textContent || ''

  return (
    <div className='video-card' onClick={() => onClick()}>
      <div className='video-tags'>
        <ul>
          {tags?.map((item, key) => (
            <li key={key}>{item}</li>
          ))}
        </ul>
      </div>
      <img src={formattedImg} alt='img' className='video-sm' />
      <div className='video-title'>
        <h3>{t(formattedTitle)}</h3>
        <p>{t(formattedDescription)}</p>
      </div>
    </div>
  )
}

type FootageCards = {
  img: string
  onClick: CallableFunction
  title: string
  discription: string
  tags: string[]
}
