import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Logout } from '../../api/Auth.services'
import { logoutIcon } from '../../helper/iconPath'

const LogoutButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const logout = () => {
    Logout()
    localStorage.clear()
    navigate('/login')
  }

  return (
    <a className='nav-link' onClick={() => logout()}>
      <img src={logoutIcon} alt='icon' />
      {t('Logout')}
    </a>
  )
}

LogoutButton.propTypes = {}

export default LogoutButton
