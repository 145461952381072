import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GetTopicsById } from '../../api/Topic.services'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { FootageCards } from '../../components/Card/FootageCards'
import Loader from '../../components/Loader/Loader'
import { useTopicContext } from '../../context/Topic'
import { createSubtopic, getPaginationItems } from '../../helper/utils'
import Pagination from '../../components/Pagination/Pagination'
import FootageTagInfo from './FootageTabInfo'

import './Footage.scss'
import { usePolicyService } from '../../hooks/usePolicyService'

const Footage = () => {
  const { topics } = useTopicContext()
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const selected = searchParams.get('tag')
  const [selectedCard, setSelectedCard] = useState({ title: '', href: '' })
  const [selectedFilter, setFilter] = useState<string[]>([])
  const [footageData, setFootageData] = useState<FootageReponseType[]>([])
  const [show, setShow] = useState(false)
  const [currentPage, setPage] = useState(1)
  const navigate = useNavigate()
  const getData = usePolicyService()

  // show breadcrumbs based the params
  const breadcrumbs = useMemo(() => {
    if (selected) {
      return [
        { link: `footage/${id}`, text: 'Footage' },
        { link: '', text: selected },
      ]
    }

    return [{ link: `footage/${id}`, text: 'Footage' }]
  }, [searchParams])

  const onPageChange = (pageNo: number) => {
    setPage(pageNo)
  }

  const clearFilter = () => {
    setFilter(selected ? [selected] : [])
    setPage(1)
  }

  const onCardClicked = (item: FootageReponseType) => {
    const url = '/testfootage/footagevideo'
    navigate(url + '/' + `${encodeURIComponent(JSON.stringify(item))}`)
  }

  // filter selection and removal;
  const onFilterSelect = (id: string) => {
    if (selectedFilter.includes(id) && id !== selected) {
      const newFilter = selectedFilter.filter((item) => item !== id)
      setFilter(newFilter)
    } else {
      setFilter((prev) => [...prev, id])
    }
    setPage(1)
  }

  useEffect(() => {
    if (selected) {
      setFilter([selected])
    } else {
      setFilter([])
    }
  }, [searchParams])

  const tagsInfo = useMemo(() => {
    const footage = topics.find((item) => item.uid === id)
    const ans = footage ? createSubtopic(footage) : []
    const sortedAns = ans?.sort((a: any, b: any) => a?.title.localeCompare(b?.title))
    return sortedAns || []
  }, [topics, id])

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetTopicsById(id || '')

      if (result.data) {
        getData(result.data.policyAccepted)
      }
      if (!result.error) {
        setFootageData(result?.data?.topic?.subtopics || [])
      }
    }
    fetchData()
  }, [topics, id])

  const applyFilter = () => {
    if (selectedFilter.length === 0) {
      return footageData?.sort((a: any, b: any) => a?.title.localeCompare(b?.title))
    }

    const filter = footageData.filter((item) =>
      item.tags.some((item) => selectedFilter.includes(item)),
    )
    return filter
  }

  const filterdData = applyFilter()

  const pagedData = getPaginationItems(currentPage, 10, filterdData)
  const footage = topics.find((item) => item.uid === id)

  return !topics || topics.length === 0 ? (
    <Loader />
  ) : (
    <>
      <section
        onClick={() => {
          if (show) setShow(false)
        }}
        className='footage-container'
      >
        <section className='footage-container'>
          <section className='hero-subsection'>
            <Breadcrumb route={breadcrumbs} />
            <div
              className='hero-subsection-content'
              style={{ backgroundImage: `url(${footage?.heroBanner})` }}
            >
              <h1>{selected || footage?.title}</h1>
              {/* <h3 dangerouslySetInnerHTML={{__html: footage?.description ?? ''}}></h3> */}
              <h3>
                We want you to experience the most quintessentially “Sphere” test shoots we’ve done
                so far. <br />
                The footage in this section was shot on an iPhone within a scaled replica of Sphere,
                the “Big Dome” at Sphere Studios in Burbank. Please consider these rough reference
                materials that display theories we have expounded upon in the Ideation and Shoot
                Techniques section.
              </h3>
            </div>
          </section>

          <section className='filter-wrapper'>
            {filterdData.length > 0 ? (
              <>
                {tagsInfo.length > 0 && (
                  <div className='filter-tabs'>
                    <p
                      className='clearfilter cursorPointer'
                      onClick={() => {
                        clearFilter()
                      }}
                    >
                      {' '}
                      Clear all filters
                    </p>

                    <FootageTagInfo
                      tagsInfo={tagsInfo}
                      onFilterSelect={onFilterSelect}
                      selectedFilter={selectedFilter}
                    />
                  </div>
                )}
                <div className='video-grid-section'>
                  <div className='video-grid'>
                    <ul>
                      {pagedData.map((item, index) => {
                        return (
                          <li key={index} className='cursorPointer'>
                            <FootageCards
                              img={item?.heroBanner}
                              discription={item?.description}
                              onClick={() => onCardClicked(item)}
                              tags={item.tags}
                              title={item.title}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <Pagination
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  totalCount={filterdData.length}
                />
              </>
            ) : (
              <Loader />
            )}
          </section>
        </section>
      </section>
    </>
  )
}

export type FootageReponseType = {
  description: string
  heroBanner: string
  link: string
  tags: string[]
  title: string
}

export default Footage
