import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { makeProtectedRoutes } from '../../hoc/makeProtected'
import Category from '../Category'

const ContactPage = () => {
  // category page is reused and called with the article name
  return <Category articleName='contact_page' />
}

export const Contact = makeProtectedRoutes(ContactPage)
