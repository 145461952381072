import './FootageTabInfo.scss'
import { useLayoutEffect, useState, useRef, useEffect } from 'react'
import { NavChild } from '../../components/SideNavbar/Desktop/NavItem'
import FootageTag from './FootageTag'

const FootageTagInfo = (props: FootageTagInfoProps) => {
  const listRef = useRef<HTMLUListElement>(null)
  const { tagsInfo, onFilterSelect, selectedFilter } = props

  const [filteredTags, setFilteredTags] = useState([...tagsInfo])
  const [isCollapsed, setCollapsed] = useState(false)
  const [isVisible, setVisible] = useState(true)

  useLayoutEffect(() => {
    if (listRef.current) {
      setVisible(!(listRef?.current?.clientHeight < 87))
      setCollapsed(listRef?.current?.clientHeight > 87)
    }
  }, [])

  const filterDataBySelectionTag = (data: FootageTagInfo, name: string[]) => {
    const finalData = data.filter((value: FootageTagInfoValue) => name.includes(value.title))
    let result: FootageTagInfo = []
    if (name.length && finalData.length) {
      result = finalData.concat(data)
    } else {
      result = data
    }
    const finalResult = result.filter(
      (v1: FootageTagInfoValue, index: number) => result.indexOf(v1) === index,
    )
    setFilteredTags([...finalResult])
  }

  useEffect(() => {
    filterDataBySelectionTag(tagsInfo, selectedFilter)
  }, [selectedFilter])

  return (
    <>
      <ul
        id='filters__list'
        ref={listRef}
        className={`${isCollapsed ? 'filter__list--hide' : 'filter__list--show'}`}
      >
        {filteredTags?.map((item: FootageTagInfoValue, key: number) => (
          <>
            <FootageTag
              key={key}
              title={item?.title || ''}
              onClick={onFilterSelect}
              isActive={selectedFilter.includes(item.title)}
            />
          </>
        ))}
      </ul>
      {isVisible && (
        <>
          {!isCollapsed ? (
            <p
              className='text-center cursorPointer'
              onClick={() => {
                setCollapsed(true)
              }}
            >
              Show less filters
            </p>
          ) : (
            <p
              className='text-center cursorPointer'
              onClick={() => {
                setCollapsed(false)
              }}
            >
              View more filters
            </p>
          )}
        </>
      )}
    </>
  )
}

type FootageTagInfoProps = {
  tagsInfo: NavChild[]
  onFilterSelect: CallableFunction
  selectedFilter: string[]
}
type FootageTagInfo = NavChild[]

type FootageTagInfoValue = NavChild

export default FootageTagInfo
